<template>
  <TTabs :tabs="tabs" :activeTab="$route.query.tab" @change-tab="changeTab">
    <template #detail>
      <Order
        orderStore="order_shipment"
        orderItemStore="order_items_shipment"
        noItem
      />
    </template>
    <template #lading_bill>
      <SCardLadingBillOfOrder :order="shipment" />
    </template>
    <template #logs>
      <SCardLog :logableId="id" />
    </template>
    <template #transactions>
      <SCardTransaction :objectableId="id" />
    </template>
    <template #shipment_info>
      <SCardShipmentInfo :orderId="id" :userId="shipment.customer_id" />
    </template>
    <template #compensation>
      <SCardCompensationOrder :orderId="id" />
    </template>
    <template #credit_card>
      <SCardCreditOrder :order="shipment" orderStore="order_shipment" />
    </template>
    <template #owning_box>
      <SCardOwningBoxOfOrder :owningBoxes="shipment.owning_boxes_from_origin" />
    </template>
    <template #contract>
      <SCardContract :orderId="id" />
    </template>
    <template #lading_bill-badge>
      <CBadge color="primary">{{ ladingBills.length }}</CBadge>
    </template>
    <template #owning_box-badge>
      <CBadge color="primary">{{
        shipment.owning_boxes_from_origin.length
      }}</CBadge>
    </template>
  </TTabs>
</template>
<script>
import { mapGetters } from "vuex";
import Order from "../components/Order.vue";

export default {
  components: {
    Order,
  },
  data() {
    return {
      tabs: [
        { key: "detail", name: "Order detail", icon: "cis-library-books" },
        { key: "shipment_info", name: "Shipment info", icon: "cis-map" },
        { key: "contract", name: "Contract", icon: "cis-document" },
        { key: "credit_card", name: "Credit card", icon: "cis-credit-card" },
        { key: "lading_bill", name: "Lading bill", icon: "cis-truck" },
        { key: "owning_box", name: "SKU list", icon: "cis-box" },
        { key: "compensation", name: "Compensation", icon: "cis-library" },
        { key: "logs", name: "Logs", icon: "cis-note" },
        {
          key: "transactions",
          name: "Transactions arise",
          icon: "cis-speech-notes",
        },
      ],
    };
  },
  created() {
    if (this.id) {
      this.$store
        .dispatch("order.order_shipment.detail.fetch", this.id)
        .catch(() => {
          this.$router.push("/sale/orders/shipment");
        });
    }
  },
  computed: {
    ...mapGetters({
      shipment: "order.order_shipment.detail",
      ladingBills: "warehouse.lading_bills_of_orders.list",
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
  },
};
</script>
